@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@400;500;600;700;800&display=swap");
body {
  font-family: "Inter Tight", sans-serif;
}

p {
  color: #000000 !important;
}

.Toastify__toast--success {
  background: #42d82e;
}

/* ========== Custom MainContainer Styles =========== */

.main-container {
  height: 100%;
  width: 100%;
}

.main-container-head {
  display: flex;
  justify-content: space-between;
}

.main-container-heading {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.5px;
  margin-bottom: 20px;
}
.main-logo{
  display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    font-size: 20px;
    color: #fff;
    background: #1d2833;
    width: 280px;
    padding: 17px 25px;
    z-index: 32;
}

.main-logo img{
  margin-right: 10px;
  width: 40px;
}
.descContainerPara {
  font-size: 15px;
  font-weight: 500;
  color: black;
  opacity: 0.6;
}
.sidebar-wrapper .nav li a p.navHead {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
}
.sidebar-wrapper .nav li a p.nav-detail {
  font-size: 15px;
  font-weight: 600;
}
.sidebar-wrapper .nav li a i {
  display: block !important;
  float: unset;
  filter: invert(1);
}

.sidebar-wrapper .nav li a .nav-detail {
  color: #999fc2 !important;
  text-transform: capitalize !important;
}

/* ========== Custom Table Styles =========== */

.table .rt-tbody .rt-td {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ========== Custom Slider Styles =========== */

.slider {
  padding: 30px 40px;
  width: 1175px;
}

/*========== Custom Styles View Data  ============= */

.view-data .view-data-main-title {
  font-weight: 500;
  font-size: 26px;
  padding-bottom: 5px;
}

.view-data .view-data-body .view-data-row {
  background-color: #eaeded;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
}

.view-data .view-data-body .view-data-row .view-data-title {
  font-weight: 600;
}

.view-data .view-data-body .view-data-sub-title {
  font-size: 20px;
  font-weight: 600;
}

.view-data .view-data-footer {
  display: flex;
  justify-content: center;
}

/*========== Custom Styles Edit-Add Slider ============= */

.edit-add {
  text-align: center;
  padding: 60px 10px;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  /* box-shadow: 0 4px 29px rgba(47, 47, 47, 0.1); */
  max-width: 520px;
  margin: 0 auto;
  position: relative;
}
.edit-add-body .donationHeadSmall {
  font-family: "Inter Tight", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 1.8em;
  text-decoration: none;
  text-transform: none;
  color: black !important;
  text-align: left;
  margin-bottom: 0px;
}
.edit-add-body .smallTxt {
  font-family: "Inter Tight", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.1em;
  text-decoration: none;
  text-transform: none;
  color: rgb(128, 129, 137) !important;
  text-align: left;
}
.edit-add .edit-add-title {
  padding: 15px;
  font-size: 26px;
  font-weight: 500;
  text-align: left;
}
.edit-add-title .headTop {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.5px;
  margin-bottom: 20px;
}
.edit-add .edit-add-title .description {
  font-size: 15px;
  font-weight: 500;
  color: black;
  opacity: 0.6;
}
.edit-add .line {
  padding-top: 3%;
}

.edit-add .line .line-break {
  padding-top: 5%;
  margin-left: 25%;
}

.edit-add .edit-add-sub-title {
  font-size: 20px;
  font-weight: 500;
  text-align: start;
}

.edit-add .edit-add-body .edit-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: "gray";
}

.edit-add
  .edit-add-body
  .edit-add-field
  .form-input-field
  .MuiFormLabel-root.Mui-focused {
  color: #d82e35;
}

.edit-add
  .edit-add-body
  .form.edit-add-field
  .form-input-field
  .MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid #6c757d;
}

.edit-add
  .edit-add-body
  .edit-add-field
  .form-input-field
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border: 2px solid #d82e35;
}

.edit-add
  .edit-add-body
  .edit-add-field
  .form-input-field
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid #6c757d;
}

.MuiAutocomplete-option {
  color: #000000;
}

.edit-add .edit-add-body .edit-add-buttons {
  margin-top: 20px;
}

/* ========== Custom Button Styles =========== */

.add-btn {
  color: #ffffff !important;
  padding: 5px 15px !important;
  border-radius: 10px !important;
  border: 1px solid #333645 !important;
  background-color: #333645 !important;
}

.add-btn:hover {
  color: #ffffff !important;
  background-color: #d82e35 !important;
  border: 1px solid #d82e35 !important;
}

.view-btn {
  min-width: 45% !important;
  color: #d82e35 !important;
  border-radius: 10px !important;
  border: 1px solid #333645 !important;
}

.view-btn:hover {
  color: #ffffff !important;
  background-color: #d82e35 !important;
}

.edit-btn {
  min-width: 45% !important;
  color: #d82e35 !important;
  border-radius: 10px !important;
  border: 1px solid #333645 !important;
}

.edit-btn:hover {
  color: #ffffff !important;
  background-color: #d82e35 !important;
}

.delete-btn {
  min-width: 30% !important;
  color: #d82e35 !important;
  border-radius: 10px !important;
  border: 1px solid #d82e35 !important;
}

.delete-btn:hover {
  color: #ffffff !important;
  background-color: #d82e35 !important;
}

.cancel-btn {
  border-radius: 10px !important;
  color: #ffffff !important;
  background: #d82e35 !important;
}

.submit-btn {
  border-radius: 10px !important;
  color: #ffffff !important;
  background: #d82e35 !important;
}

.confirm-btn {
  border-radius: 10px !important;
  color: #ffffff !important;
  background: #d82e35 !important;
}

button:focus {
  outline: 0px !important;
}

/* ========== Custom AppLoader Styles =========== */

.loader-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  color: #d82e35;
}

.blue-loader {
  color: #d82e35;
}

/* ========== Main Modal Styles =========== */

.main-modal {
  max-width: 500px !important;
  top: 5%;
}

.main-modal .modal-header,
.modal-footer {
  border: none !important;
}

.main-modal .modal-content {
  background-color: #d82e35 !important;
  border-radius: 30px !important;
}

.main-modal .modal-content .modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.main-modal .modal-content .modal-header .close {
  color: #fff !important;
}

.main-modal .modal-content .modal-header .modal-logo {
  display: flex;
  justify-content: center;
}

.main-modal .modal-content .modal-header .modal-logo img {
  width: 85%;
  padding: 10px;
  margin: 30px 0px;
  border-radius: 20px;
  background-color: #0f0f0f;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.main-modal .modal-content .modal-body {
  padding: 0px;
}

/* ========== Custom Styles Message Modal =========== */

.message-modal {
  max-width: 1050px !important;
  top: 0% !important;
}

.modal.show .modal-dialog.message-modal {
  transform: translate(0, 5%);
}

.message-modal .message-modal-title p {
  color: #fff !important;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.message-modal .message-modal-line hr {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.message-modal .modal-body .message-text {
  display: flex;
  justify-content: center;
  padding: 25px;
  margin: 25px 0px 80px;
  border-radius: 15px;
  background: rgba(10, 10, 10, 0.7);
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.message-modal .modal-body .message-text h3 {
  margin-bottom: 0px;
}
.wrap{
  position: relative;
}
.info-wrap {
  position: absolute;
  left: calc(100% + 10px);
  width: 20px;
  height: 20px;
  display: flex;
  bottom: 35px;
  z-index: 2;
}
.info-wrap.right{
  left: calc(100% - 10px);
}
.info-wrap img {
  cursor: pointer;
}

.name-notice {
  position: absolute;
  left: 0;
}

.info-wrap:hover .info {
  opacity: 1;
  visibility: visible;
}

.info-wrap .info {
  position: absolute;
  top: 0;
  right: calc(100% + 2px);
  background: #272A33;
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.2s;
  top: 10px;
  /* border: 1px solid #55cbc6; */
  width: 270px;
  z-index: 2;
  text-align: left;
  display: flex;
  align-items: flex-start;
}

.info-wrap .info img {
  margin-right: 12px;
  width: 20px;
}

.info-wrap .info p {
  width: calc(100% - 32px);
  word-break: break-word;
  color: #fff !important;
}

/*========== Responsive ============= */

@media only screen and (max-width: 650px) {
  .slider {
    padding: 30px;
    width: 500px;
  }

  .slider .edit-add {
    padding: 20px 10px;
  }

  .slider .edit-add .slider-body .edit-add-buttons {
    padding: 30px;
  }
}

@media only screen and (max-width: 425px) {
  .slider {
    padding: 20px;
    width: 400px;
  }
}

@media only screen and (max-width: 375px) {
  .slider {
    padding: 10px;
    width: 325px;
  }
}

/* new styles for new ui */
.bgPanel {
  background-image: url(/images/bgTop-svg.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0.63;
  aspect-ratio: 1.55731 / 1;
  flex: 0 0 auto;
  mix-blend-mode: overlay;
  overflow: visible;
  position: absolute;
  width: 590px;
  height: 477px;
  top: 0px;
  right: 0px;
}
.bgPanel2 {
  background-image: url(/images/bgBottomCropped.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  aspect-ratio: 1.55731 / 1;
  bottom: 0px;
  flex: 0 0 auto;
  height: 100px;
  mix-blend-mode: overlay;
  overflow: visible;
  position: absolute;
  width: 890px;
  left: 0px;
}
.logoHeader img {
  margin-right: 10px;
  width: 40px;
}
.logoHeader {
  color: black !important;
  font-weight: 600;
  font-size: 18px !important;
  color : #097238 !important;
}
.detailsAdmin {
  margin-right: 15px;
}
.detailsAdmin h4{
  display: flex;
  align-items: center;
}
.detailsAdmin span{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #28B467 !important;
  display: inline-block;
  margin-left: 10px;
}
.detailsAdmin h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1.2em;
  color: #161827;
  margin-bottom: 0px;
  text-align: right;
}
.detailsAdmin p {
  color: #999fc2 !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.1em;
}
.navBarFlex {
  display: flex;
  margin-top: 30px;
  align-items: flex-start;
  padding-bottom: 30px;
}
.navBarFlex .sidebar {
  position: sticky;
  top: 0px;
  width: 280px;
}

.navBarTop {
  height: 85px;
  width: calc(100% - 280px);
  margin-left: auto;
  padding: 0 40px;
}
.sideBarImgs {
  height: 32px;
  margin-bottom: 5px;
}
.sideBarImgs.activeImg {
  display: none;
}
.sidebar-wrapper ul li.active a{
  background: rgba(255, 255, 255, 0.10) !important;
  border-radius: 5px;
}

.sidebar-wrapper ul li.active .navHead {
  color: white !important;
}
.sidebar-wrapper ul li.active .sideBarImgs.activeImg {
  display: inline-block;
}
.sidebar-wrapper ul li.active .sideBarImgs.imgBlack {
  display: none;
}
.InputFieldCustom {
  height: 90px;
  width: 100%;
  border-bottom-width: 2px;
  border-color: rgba(208, 211, 221, 1);
  border-left-width: 2px;
  border-right-width: 2px;
  border-style: solid;
  border-top-width: 2px;
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 10px;
}
.form-input-field .MuiInputBase-root {
  height: 90px;
  border-bottom-width: 2px;
  border-color: rgba(208, 211, 221, 1);
  border-left-width: 2px;
  border-right-width: 2px;
  border-style: solid;
  border-top-width: 2px;
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 10px;
  opacity: 1;
}
.form-input-field .MuiFilledInput-underline:before {
  border-bottom: none;
}
.form-input-field .MuiFilledInput-underline:after {
  border-bottom: none;
}
.form-input-field .MuiFilledInput-root:hover, .form-input-field .MuiFilledInput-root:focus {
  background-color: #fff;
  /* border-bottom: none; */
  border-color: #55cbc6 !important;
}
.form-input-field .MuiFilledInput-underline:hover:before {
  border-color: transparent;
}
.form-input-field .MuiInputLabel-filled.MuiInputLabel-shrink label {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Inter Tight", sans-serif !important;
}
.form-input-field .MuiFormLabel-root.Mui-focused {
  color: #3552fa !important;
}
.edit-add-buttons .submit-btn {
  width: 100%;
  height: 69px;
  background: #28B467 !important;
  font-size: 18px;
  font-weight: 500;
}
.main-panel {
  width: calc(100% - 280px);
  padding: 0 40px;
}
.logoutSectionBtn img.logoutImg {
  height: 23px;
  margin-right: 7px;
}
.logoutSectionBtn button.btnLogout {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  box-shadow: none;
  
}
.logoutSectionBtn button.btnLogout p{
  font-weight: 600;
  font-size: 15px;
  color: #fff !important;
}
.logoutSectionBtn button.btnLogout img{
  filter: invert(1);
}
.logoutSectionBtn {
  margin: 10px 15px 0;
  border-radius: 30px;
  color: #ffffff;
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  padding: 10px 8px;
  line-height: 1.625rem;
  margin-top: 50px;
}
.layoutSetting {
  position: relative;
  z-index: 999;
  min-height: 100vh;
}

.layoutSetting:before {
  position: absolute;
  content:'';
  width: 280px;
  left: 0;
  top: 0;
  height: 100%;
  background: #1D2833;
}

.labelReqHeadTop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: absolute;
  top: 14px;
  left: 14px;
  right: 14px;
}
.edit-add-field {
  position: relative;
}
.labelReqHeadTop h4:first-child {
  font-size: 15px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 1.1em;
  color: #cdd0da !important;
  text-decoration: none;
  text-transform: none;
  margin-bottom: 0px;
  font-family: "Inter Tight", sans-serif;
  font-weight: 600;
}
.labelReqHeadTop h4:nth-child(2) {
  font-size: 12px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 1.1em;
  color: #cdd0da !important;
  text-decoration: none;
  text-transform: none;
  margin-bottom: 0px;
  font-family: "Inter Tight", sans-serif;
  font-weight: 600;
}
.form-input-field input.MuiFilledInput-input {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.2em;
  padding-top: 32px;
  font-family: "Inter Tight", sans-serif;
}
.edit-add-buttons .MuiButton-label {
  text-transform: initial;
  font-weight: 500;
  font-family: "Inter Tight", sans-serif;
}
.table .rt-tr-group:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}
.table {
  border: none !important;
}
.table .rt-td,
.rt-th {
  border-right: none !important;
}
.table .rt-thead {
  font-weight: 700;
}
.add-donations-head h2{
  color: #1D2833;
}

.add-donations-head{
  display: flex;
  align-items: flex-end;
  background: #fff;
  padding: 40px 24px;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 16px;
}

.add-donations-head p{
  color: #1D2833;
  font-size: 15px;
}

.add-donations-head button{
  font-weight: 500;
  font-size: 16px;
  background: #28B467;
  border-radius: 5px;
  padding: 10px 30px;
  color: #fff;
  display: flex;
  align-items: center;
  border: 0;
}

.add-donations-head button img{
  margin-left: 16px;
}
.add-donations-steps-boxes-wrap{
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}

.add-donations-steps-boxes-wrap .step-box{
  width: calc(50% - 16px);
  margin: 8px;
  background-color: #fff;
  padding: 16px;
  display: flex;
}

.add-donations-steps-boxes-wrap .step-box .icon-wrap{
  background: #F5F5F5;
  height: 96px;
  width: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-donations-steps-boxes-wrap .step-box .icon-wrap img{
  width: 64px;
  height: 64px;
  object-fit: contain;
}

.add-donations-steps-boxes-wrap .step-box .step-box-info{
  width: calc(100% - 96px);
  padding-left: 12px;
}

.add-donations-steps-boxes-wrap .step-box .step-box-info span{
  color: #A9AAAD;
  font-size: 15px;
  display: block;
  margin-bottom: 16px;
}

.add-donations-steps-boxes-wrap .step-box .step-box-info h3{
  color: #1D2833;
  font-size: 20px;
}
.progress-wrap{
  margin-bottom: 30px;
}
.progress-bar{
  background: #E8E9EB !important;
  width: 100%;
  height: 12px;
}
.progress-bar-fill{
  background: #28B467;
  height: 100%;
}

.steps-form-wrapper{
  background: #fff;
  padding: 32px 24px 40px;
  position: relative;
  margin-bottom: 72px;
}

.progress-head{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 7px;
}

.progress-head h3{
  color: #1D2833;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.progress-head span{
  color: #A9AAAD;
  font-size: 15px;
}

.steps-form-wrapper .step h2{
  margin-bottom: 16px !important;
  color: #1D2833;
}

.steps-form-wrapper .step > p{
  color: #A9AAAD;
  font-size: 15px;
  line-height: 1.4;
  margin-bottom: 30px;
}

.steps-form-wrapper .step .btn-wrap{
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -72px;
}

.steps-form-wrapper .step .btn-wrap .btn{
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 44px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 35px;
}

.steps-form-wrapper .step .btn-wrap .btn.back{
  background: transparent !important;
  border: 1px solid #1D2833 !important;
  color: #1D2833;
  box-shadow: none;
}

.form-wrap input,
.form-wrap textarea,
.form-wrap select {
  background: #E8E9EB;
  border: 0;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #464F58;
  padding: 20px 35px;
  height: 70px;
  width: 100%;
  outline: 0;
}

.steps-form-wrapper .step .btn-wrap .btn.back img{
  margin-right: 10px;
}

.steps-form-wrapper .step .btn-wrap .btn.next{
  background: #28B467 !important;
  box-shadow: none;
}

.steps-form-wrapper .step .btn-wrap .btn.next img{
  margin-left: 10px;
}

.step .form-wrap.dir-col{
  flex-direction: column;
  max-height: 274px;
}
.step .form-wrap.dir-col.no-height{
  max-height: initial;
}
.step .form-wrap{
  margin: 24px -8px -8px;
  padding: 0;
}
.step .form-wrap .wrap.flex{
  display: flex;
  justify-content: space-between;
}

.step .form-wrap .wrap.flex .form-group{
  width: 48.5%;
}

.step .form-wrap.full-width >div textarea{
  height: 250px;
}
.step .form-wrap >div{
  margin: 8px;
  max-width: 500px;
}
.add-multiple{
  background: #28B467 !important;
  box-shadow: none;
  color: #fff;
  border-radius: 5px;
  height: 70px;
  width: 250px;
  padding: 12px 20px;
  font-size: 18px;
  border: 0;
  outline: 0;
  margin-left: 8px;
  width: calc(100% - 16px);
  max-width: 500px;
  margin-bottom: 16px;
  display: block;
}
.add-multiple.remove{
  background: red !important;
}
.step .form-wrap input, .step .form-wrap textarea, .step .form-wrap select{
  line-height: 28px;
}

.step .form-wrap >div label{
  color: #A9AAAD;
}

.step .form-wrap >div.addmore button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 12px 25px;
}

.step .form-wrap >div.addmore button img{
  margin-left: 10px;
}

.step .form-wrap select{
  /* background-image: url("../img/select-arrow.png"); */
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
  background-size: 24px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.profile .tab-content {
  margin-top: 20px;
}

.profile .tab-content .filters-wrap {
  margin-bottom: 32px;
}

.profile .tab-content .filters-wrap form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile .tab-content .filters-wrap form .left {
  display: flex;
  align-items: center;
}

.profile .tab-content .filters-wrap form .left select {
  display: flex;
  align-items: center;
  background: #343444;
  border: 0 !important;
  color: #fff;
  font-size: 15px;
  font-weight: normal;
  background-image: url(../img/arrow-down.png);
  background-position: calc(100% - 5px);
  background-repeat: no-repeat;
  padding: 11px 50px 11px 15px;
  box-shadow: none !important;
  height: 45px;
}

.profile .tab-content .filters-wrap form .left .icons-wrapper {
  background: #343444;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  height: 45px;
  margin-left: 12px;
}

.profile .tab-content .filters-wrap form .left .icons-wrapper img {
  margin-right: 16px;
}

.profile .tab-content .filters-wrap form .left .icons-wrapper img:last-child {
  margin-right: 0;
}

.profile .tab-content .filters-wrap form .left .icons-wrapper span {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  margin-right: 16px;
}


.profile .tab-content .filters-wrap form .time {
  background: #343444;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  height: 45px;
}

.profile .tab-content .filters-wrap form .time span {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  margin-right: 16px;
  opacity: 0.5;
}

.profile .tab-content .filters-wrap form .time span.active {
  opacity: 1;
}

.profile .tab-content .table-wrap {
  overflow: auto;
  margin-bottom: 70px;
}

.profile .tab-content table {
  width: 100%;
  min-width: 920px;
}


.profile .tab-content table th,
.profile .tab-content table td {
  color: #FFF;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 0;
}

.profile .tab-content table tr {
  border-bottom: 1px solid #1F1F2C;
}

.profile .tab-content table tbody tr:last-child {
  border-bottom: 0;
}

.profile .tab-content table td.percentage {
  color: #47A432;
}

.profile .tab-content table tr.loss td.percentage {
  color: #DF4949;
}

.profile .tab-content table th img {
  margin-left: 7px;
}

.profile .tab-content table td .img-wrap {
  position: relative;
  width: 56px;
  height: 56px;
  display: inline-block;
  margin-right: 16px;
}

.profile .tab-content table td .img-wrap img {
  border-radius: 15px;
  object-fit: cover;
  width: 56px;
  height: 56px;
}

.profile .tab-content table td .img-wrap img.top-seller-mark {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.profile .tab-content .signin {
  max-width: 100%;
  text-align: left;
}
.profile .tab-content .create .MuiFormControl-fullWidth{
  max-width: 690px;
  border-radius: 8px;
}
.profile .tab-content .create .MuiFormControl-fullWidth .MuiInputBase-colorPrimary{
  background: #fff;
  padding: 0;
  font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666 !important;
    border-radius: 8px;
    height: auto !important;
    margin-top: 0 !important;
}
.profile .tab-content .create .MuiFormControl-fullWidth .MuiInputBase-colorPrimary .MuiSelect-select{
  font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666 !important;
    padding: 13px 20px;
}
.profile .tab-content .create h2:first-child {
  margin-bottom: 50px;
}

.profile .tab-content .create h2 {
  margin-bottom: 12px;
  font-size: 40px;
  color: #39343A;
}

.profile .tab-content .create p {
  margin-bottom: 24px;
  color: #666666;
}

.profile .tab-content .create .form-group {
  margin-bottom: 48px;
}

.profile .tab-content .create .form-group>span {
  color: #666666;
  font-size: 14px;
  margin-top: 12px;
  display: block;
}

.profile .tab-content .create .form-group input,
.profile .tab-content .create .form-group select,
.profile .tab-content .create .form-group textarea,
.profile .tab-content .create .form-group .upload-file {

  max-width: 690px;
}

form p a {
  color: #28B467;
}

.profile .tab-content .create .choose {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}

.profile .tab-content .create .choose .box {
  width: 49%;
  display: flex;
  align-items: center;
  padding: 24px;
  background: #fff;
  border-radius: 8px;
}

.profile .tab-content .create .choose .box.active {
  background: #343444;
}

.profile .tab-content .create .choose .box img {
  width: 120px;
  border-radius: 8px;
  object-fit: cover;
}

.profile .tab-content .create .choose .box .info {
  width: calc(100% - 120px);
  padding-left: 32px;
}

.profile .tab-content .create .choose .box .info h3 {
  font-size: 20px;
  font-weight: 700;
  color: #39343A;
  margin-bottom: 16px;
}

.profile .tab-content .create .choose .box .info p {
  margin-bottom: 0;
  color: #666666;
  font-size: 14px;
}

.profile .tab-content .create .common-btn.create-btn {
  background: #28B467;
  width: 183px;
  height: 54px;
  border-radius: 100px;
}

.profile .tab-content .create {
  margin-bottom: 30px;
}

.profile .tab-content .create .check-box {
  width: 52px;
  height: 26px;
  background: #EBEBEB;
  border-radius: 50px;
}

.profile .tab-content .create .check-box input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 52px;
  height: 26px;
  background-color: #39343A !important;
  border-radius: 50px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.4s;
  margin: 0 !important;
  border: 0 !important;
}

.profile .tab-content .create input[type="checkbox"]::after {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  top: 4px;
  left: 6px;
  background: #28B467;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}

.profile .tab-content .create input:checked[type="checkbox"]::after {
  left: 50%;
}

.profile .tab-content .create .form-group .label-head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
  max-width: 690px;
}

.profile .tab-content .create .form-group .label-head label {
  display: flex;
  align-items: center;
  margin: 0;
}

.upload-file {
  position: relative;
  border-radius: 8px;
  padding: 30px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
}

.upload-file input {
  position: absolute;
  width: 100%;
  height: 100% !important;
  left: 0;
  opacity: 0;
  top: 0;
}

.upload-file span {
  color: #666666;
  font-size: 14px;
  line-height: 22px;
}

.profile .common-btn{
  background: #28B467;
  color: #fff;
  border-radius: 100px;
  padding: 15px 35px;
  border: 0;
}
.profile p img{
  display: inline;
}
.signin form input,
.signin form select,
.signin form textarea {
    border: 0 !important;
    border-radius: 8px;
    padding: 13px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666 !important;
    background-color: #fff !important;
    height: 48px;
    width: 100%;
    display: block;
}
form label{
  display: block;
  margin-bottom: 16px;
  font-weight: 600;
}
.signin form select {
    background-color: #fff !important;
}

.signin form select option {
    color: #666666 !important;
}

.signin form textarea {
    resize: none;
    height: 120px;
}

.signin form input::placeholder,
.signin form textarea::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #666666;
}

.signin form .form-check-input[type=checkbox] {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    padding: 0;
}

.signin form .form-check {
    max-width: 130px;
    width: 100%;
    margin-bottom: 33px;
}

.signin form .form-check label {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    margin-left: 8px;
}

@media (max-width: 1199px) {
  .profile .tab-content .create .choose {
    flex-direction: column;
}

.profile .tab-content .create .choose .box {
    width: 100%;
    margin-bottom: 24px;
}

.profile .tab-content .create .choose .box:last-child {
    margin-bottom: 0;
}
}

@media (max-width: 991px) {
  .edit-add-body form{
    padding-right: 20px;
  }

  .navBarFlex .sidebar-wrapper{
    width: 100% !important;
    }
  .transactionDetailsTax{
    padding:20px 10px !important;
  }
  .logoutSectionBtn{
    margin: 0px !important;
    padding: 0px;
  }
  .logoutSectionBtn button.btnLogout p{
    display: none;
  }
  .navbar .navbar-brand {
    margin-left: 0px !important;
    margin-right: 0px;
    font-size: 24px;
  }
  .navbar .photo img {
    width: 34px !important;
    height: 38px !important;
  }
  .detailsAdmin h4 {
    font-size: 16px;
  }
  .detailsAdmin p{
    font-size: 11px;
  }
  .detailsAdmin{
    margin-right: 8px;
  }
  .navBarTop .navbar.navbar-absolute{
    height: 55px;
  }
  .layoutSetting{
    padding-left: 10px;
    padding-right: 10px;
  }
  .navBarFlex .sidebar ul li{
    width: 14.28%;
  }
  .logoutSectionBtn button.btnLogout{
    width: 100%;
    border: none;
    height: 100%;
  }
  .logoHeader img {
    margin-right: 5px;
  }
  .navBarFlex {
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .navBarFlex .sidebar {
    width: 100%;
  }
  .sidebar .nav li > a, .off-canvas-sidebar .nav li > a{
    justify-content: center;
  }
  .navBarFlex .nav {
    display: flex !important;
    width: 100%;
    justify-content: space-between;
    
  }
  .navBarFlex .sidebar{
    width: 100%;
    background-color: #1D2833;
    border-radius: 8px;
    margin-bottom: 10px;
    z-index: 999;
  }
  .sidebar-wrapper .nav li a p.navHead {
    display: none;
  }
  .edit-add{
    padding: 20px 0px;
    max-width: 100%;

  }
  .edit-add-title .headTop{
    margin-bottom: 5px;
  }

  .form-input-field .MuiInputBase-root{
    height: 70px;
  }
  .labelReqHeadTop h4:first-child{
    font-size: 10px;
  }
  .labelReqHeadTop h4:nth-child(2){
    font-size: 9px;
  }
  .edit-add-buttons .submit-btn{
    height: 50px;
    font-size: 14px;
  }
 
  .main-container-heading{
    font-size: 22px;
  }
  .descContainerPara {
    font-size: 16px;
  }
  .edit-add-body .smallTxt{
    font-size: 9px;
    width: 100%;
  }
  .main-container-heading
  .form-input-field input.MuiFilledInput-input {
  font-size: 16px;
  }
  .sideBarImgs {
    height: 23px;
    margin-bottom: 0px;
  }
  .sidebar-wrapper .nav li a .nav-detail {
    display: none;
  }
  .sidebar .nav .nav-link {
    margin: 0px 0px !important;
    text-align: center;
  }
  .bgPanel,
  .bgPanel2 {
    display: none;
  }

  .layoutSetting:before, .main-logo{
    display: none;
  }
  .navBarTop{
    width: 100%;
    padding: 10px 0 0;
  }
}

@media screen and (max-width: 767px){
  .add-donations-steps-boxes-wrap .step-box{
    width: 100%;
  }
}

@media screen and (max-width: 575px){
  .profile .common-btn {
    padding: 8px 15px;
  }
  .upload-file {
    padding: 20px 15px;
  }
}

.transactionDetailsTax{
  padding: 30px 20px;
}
.gap-small{
  gap: 10px;
}